type DateTimeFormat =
  | 'DD/MM/YY HH:mma'
  | 'DD/MM/YYYY'
  | 'DD MMM YYYY, HH:mm'
  | 'DD/MM/YYYY, HH:mm'
  | 'DD MMM YYYY HH:MM';

const DATE_FORMAT_DEFAULT = 'DD MMM YYYY HH:MM';

export const formatDate = (
  dateString: string | number | Date,
  format: DateTimeFormat = DATE_FORMAT_DEFAULT,
): string => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return '';
  }

  try {
    if (format === 'DD/MM/YYYY, HH:mm') {
      const formatDateOptions: Record<string, string | boolean> = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        hour12: false,
      };

      const dataString = date.toLocaleString('en-US', formatDateOptions);
      const [dayPart, timePart] = dataString.split(', ');
      const [month, day, year] = dayPart.split('/');
      const [hour, minute] = timePart.split(':');

      return [[day, month, year].join('/'), [hour, minute].join(':')].join(
        ', ',
      );
    }

    if (format === 'DD/MM/YYYY') {
      const options: Record<string, string> = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      };

      const formattedDate = date.toLocaleString('en-US', options);

      return formattedDate;
    }

    if (format === 'DD MMM YYYY HH:MM') {
      const options = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      } as Intl.DateTimeFormatOptions;

      const formattedDate = date.toLocaleString('en-GB', options);

      let [datePart, timePart] = formattedDate.split(', ');

      datePart = datePart.replace('.', '');

      const [day, month, year] = datePart.trim().split(' ');

      let [hour, minute] = timePart.trim().split(':');
      const minuteClean = minute.slice(0, 2);

      const hourClean = parseInt(hour, 10);
      const formattedHour = (hourClean % 24 || 0).toString().padStart(2, '0');

      return `${day} ${month} ${year} ${formattedHour}:${minuteClean}`;
    }

    if (format === 'DD/MM/YY HH:mma') {
      const options: Record<string, string> = {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      };

      const formattedDate = date.toLocaleString('en-US', options);
      const [datePart, timePart] = formattedDate.split(', ');

      const [month, day, year] = datePart.split('/');

      return `${day}/${month}/${year.slice(-2)} ${timePart}`;
    }

    if (format === 'DD MMM YYYY, HH:mm') {
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        hour12: false,
        minute: '2-digit',
      };

      const formattedDate = date.toLocaleString('en-US', options);

      return formattedDate.replace(',', '');
    }

    return '';
  } catch (error) {
    return '';
  }
};

export function convertSecondsToHMS(inputSeconds: any): string {
  try {
    if (typeof inputSeconds !== 'number' || inputSeconds < 0) {
      return `${inputSeconds} seconds`;
    }
    const hours = Math.floor(inputSeconds / 3600);
    const minutes = Math.floor((inputSeconds - hours * 3600) / 60);
    const seconds = inputSeconds - hours * 3600 - minutes * 60;
    const secondsFormatted = seconds.toString().padStart(2, '0');
    let result = `${hours}:${minutes}:${secondsFormatted}`;

    return result;
  } catch (e) {
    return '';
  }
}

export function isTimestampInPast(timestamp: number): boolean {
  const dateFromTimestamp = new Date(timestamp);
  const currentDate = new Date();
  return dateFromTimestamp < currentDate;
}
